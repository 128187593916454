<template>
  <div class="header py-0 shadow bg-white mt-3">
    <div class="container-fluid">
      <div class="header-body">
        <div class="row py-3">
          <nav aria-label="breadcrumb" class="ml-2">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item">
                <a href="#"><i class="fas fa-link"></i></a>
              </li>
              <li class="breadcrumb-item"><a href="#"></a></li>
                List of Service Catalog
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-2">
    <button
      class="mb-2 btn btn-default btn-sm"
      @click="
        $router.push({
          name: 'New Service Catalog',
          params: { uuid: this.$route.params.uuid },
        })
      "
    >
      New
    </button>
    <div class="card shadow w-100 p-4">
      <loader v-if="!data_loaded" />
      <data-table
        v-if="data_loaded"
        id="service_catalog_list"
        :options="dtb_options.extra_options"
        :actions="dtb_options.actions"
        :data="dtb_options.data"
        :columns="dtb_options.columns"
      />
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/Datatable";
import IndependentLoader from "../../components/IndependentDefaultLoader";
import { db } from "../../firebase";
import { getDocs, collection, doc, deleteDoc } from "firebase/firestore";
import { alert } from "../../utils/alertUtils";
// import { auth } from "../../firebase"

export default {
  name: "services",
  components: {
    "data-table": DataTable,
    loader: IndependentLoader,
  },
  data() {
    return {
      th: this,
      data_loaded: false,
      show_items: true,
      services: [],
      //   user_profile: this.$store.getters.getUserProfile,
      dtb_options: {
        data: [],
        actions: [
          {
            btnText: "Delete",
            btnClass: "btn btn-white text-danger btn-sm",
            iconClass: "fas fa-trash",
            btnFn: (uuid) => {
              this.delete_service(uuid);
            },
            btnParamName: "uuid",
          },
          {
            btnText: "Detail",
            btnClass: "btn btn-white text-info btn-sm",
            iconClass: "fas fa-eye",
            btnFn: (uuid, name) => {
              this.$router.push({
                name: "Service Catalog Detail",
                params: {
                  serviceCatalogUuid: uuid,
                  name: name,
                },
              });
            },
            btnParamName: "uuid",
          },
        ],
        columns: [
          {
            title: "Name",
            data: "name",
          },
          {
            title: "Number of Fields",
            render: (data) => {
              return data["fields"].length;
            },
          },
          //   { title: "Position", data: "position" },
        ],
        extra_options: {},
      },
    };
  },
  methods: {
    // dtb_on_click:function(row){
    //    var uuid=row.uuid
    //    this.$router.push({ name: "Service Catalog Detail", params: { uuid } });
    // },

    // function to fetch subservices of the parent service or carrier
    // route.param uuid is the uuid of the parent of the service.
    fetch_service_catalogs: function() {
      getDocs(collection(db, `serviceCatalog`))
        .then((snapShots) => {
          snapShots.forEach((document) => {
            let serviceCatalog = document.data();
            serviceCatalog.uuid = document.id;
            this.dtb_options.data.push(serviceCatalog);
          });
        })
        .then(() => {
          this.data_loaded = true;
        })
        .catch(() => {
          this.data_loaded = false;
          alert.error("an error occured! please try again.");
        });
    },

    //function to delete service. with uuid as an argument which is the sub service uuid.
    // route.param uuid is the uuid of the parent of the service.
    delete_service: function(uuid) {
      const ref = doc(db, "serviceCatalog", uuid);
      deleteDoc(ref)
        .then(() => {
          this.dtb_options.data = [];
          this.fetch_service_catalogs();
          alert.success_center("You have successfully deleted Service Catalog");
        })
        .catch(() => {
          alert.error("Error occured in deleting carrier");
        });
    },
  },
  created() {
    this.fetch_service_catalogs();
  },
};
</script>

<style></style>
